import { useLocation } from "react-router";

const useActivekey = (route) => {
  const location = useLocation();
  const currentSegments = location.pathname.split("/").filter(Boolean);
  const currentKey = "/" + currentSegments.slice(1).join("/");
  const activeKey = currentKey === "/" + route;
  return { activeKey };
};

export default useActivekey;
