import React from "react";

import Notifications from "../Header/Notifications";
import useGetPageName from "../../Hooks/useGetPageName";
import { useUserProvider } from "../../Context/UserProvider";
import { handleIsMobileSidebarToggled } from "../../Signals/mobileSidebar";
const Header = () => {
  const { user } = useUserProvider();
  const pageName = useGetPageName();
  const userName = user.value.fname ? `${user.value.fname}'s ${pageName}` : "";

  return (
    <div className="header">
      <nav className="navbar py-4">
        <div className="container-xxl">
          <div className="fs-5 ml-0 fw-semibold text-koin">{userName}</div>
          {/* <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
            <Notifications />
          </div> */}
          <button
            className="navbar-toggler p-0 border-0 menu-toggle order-2"
            type="button"
            onClick={handleIsMobileSidebarToggled}
          >
            <span className="fa fa-bars"></span>
          </button>
          <div></div>
        </div>
      </nav>
      <div className="container-xxl position-relative" id="commansidebar">
        <div className="row">
          <div className="col-md-12"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
