import React, { createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { fetchUserData } from "../Services/usersService";
import { useSignal } from "@preact/signals-react";
const defaultValues = {
  user: {},
  userLoading: false,
};

const UserContext = createContext(defaultValues);
const UserProvider = ({ children }) => {
  const user = useSignal(null);
  const userLoading = useSignal(true);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  const updateUser = (data) => {
    console.log("Update");
    user.value = {
      ...user.value,
      fname: data.fname,
      lname: data.lname,
      country: data.country,
      dob: data.dob,
      mob: data.mob,
      yob: data.yob,
    };
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        userLoading.value = true;
        try {
          const userData = await fetchUserData(token);
          if (userData) {
            user.value = userData;
          } else {
            user.value = null;
          }
        } catch (error) {
          user.value = null;
        } finally {
          userLoading.value = false;
        }
      } else {
        userLoading.value = false;
      }
    };

    fetchUser();
  }, [token]);

  const logOut = () => {
    user.value = null;
    sessionStorage.removeItem("token");
    navigate("/auth/login");
  };

  return (
    <UserContext.Provider value={{ user, logOut, userLoading, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
export default UserProvider;
export const useUserProvider = () => useContext(UserContext);
