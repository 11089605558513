import koinHeaders from "../Helper/koinHeaders";
import {toast} from "react-toastify";
import {toastMessage} from "../Helper/toastMessage";

const apiBase = process.env.REACT_APP_API_BASE_URL;
export const login = async (loginData) => {
  try {
    const response = await fetch(`${apiBase}/auth/local`, {
      method: "POST",
      headers: koinHeaders(false),
      body: JSON.stringify(loginData),
    });
    return await response.json();
  } catch (error) {
    toast.error(toastMessage.internal);
    console.log(error);
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await fetch(`${apiBase}/auth/reset-password`, {
      method: "POST",
      headers: koinHeaders(false),
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    toast.error(toastMessage.internal);
    console.log(error);
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await fetch(`${apiBase}/auth/forgot-password`, {
      method: "POST",
      headers: koinHeaders(false),
      body: JSON.stringify({ email: email }),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};
