import React from "react";

const Terms = () => {
  return (
    <div className="container d-flex flex-column terms flex-md-row align-items-center justify-content-between">
      <div className="d-flex gap-3 text-dark order-2 order-md-1">
        <span className="text-muted">© 2023</span>
        <a
          href={process.env.REACT_APP_KOIN_GLOBAL_DOMAIN}
          target="_blank"
          className="text-koin"
          rel="noreferrer"
        >
          Global Domain Media
        </a>
      </div>

      <div className="nav nav-dark order-1 order-md-2">
        <a
          href={`${process.env.REACT_APP_KOIN_LEGAL_BASE}/tos`}
          target="_blank"
          className="text-koin nav-link px-3"
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </div>
    </div>
  );
};

export default Terms;
