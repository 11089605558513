import Gift from "../../../assets/svg/Gift.svg";
import Git4 from "../../../assets/svg/Git4.svg";
import IncomingBox from "../../../assets/svg/Incoming-box.svg";
import Settings from "../../../assets/svg/Settings-2.svg";
import Lock from "../../../assets/svg/Lock.svg";
import Visible from "../../../assets/svg/Visible.svg";
import OutGoingEmail from "../../../assets/svg/Outgoing-mail.svg";
import Wallet3 from "../../../assets/svg/Wallet3.svg";
import Layers from "../../../assets/svg/Layers.svg";
import Safe from "../../../assets/svg/Safe.svg";
import Email from "../../../assets/svg/Email.svg";
import Group from "../../../assets/svg/Group.svg";
import Layout4 from "../../../assets/svg/Layout-4-blocks.svg";
import Thunder from "../../../assets/svg/Thunder.svg";
const desktopLinks = [
  {
    name: "General",
    image: Layout4,
    title: "General",
    isCategory: false,
    isApp: false,
    children: [
      {
        title: "Dashboard",
        action: "View",
        image: Layers,
        route: "",
      },
    ],
  },
  {
    name: "Account",
    identifier: "account",
    image: Group,
    title: "Account",
    isCategory: false,
    isApp: false,
    children: [
      {
        title: "Personal Info",
        action: "Click to Edit",
        image: Settings,
        route: "uprofile/edit",
      },
      {
        title: "Marketing Settings",
        action: "Click to Edit",
        image: IncomingBox,
        route: "marketing_settings",
      },
      {
        title: "Security",
        action: "Set Password",
        image: Lock,
        route: "upass/edit",
      },
      {
        title: "Logout",
        action: "End Session",
        image: Thunder,
        route: "auth/login",
      },
    ],
  },
  {
    name: "Invites",
    identifier: "invites",
    image: Email,
    title: "Invites",
    isCategory: false,
    isApp: false,
    children: [
      {
        title: "My Invites",
        action: "View",
        image: Visible,
        route: "my_invites",
      },
      {
        title: "Invite Friends",
        action: "Send an Invitation",
        image: OutGoingEmail,
        route: "invite",
      },
    ],
  },
  {
    name: "My KOIN",
    identifier: "mykoin",
    image: Safe,
    title: "My KOIN",
    isCategory: false,
    isApp: false,
    children: [
      {
        title: "My Bonuses",
        action: "Click to View",
        image: Gift,
        route: "my_bonuses",
      },
      {
        title: "My Rewards",
        action: "Click to View",
        image: Wallet3,
        route: "my_rewards",
      },
      {
        title: "My Rewards Chart",
        action: "Click to View",
        image: Wallet3,
        route: "my_rewards_chart",
      },
      {
        title: "My Network",
        action: "Click to View",
        image: Git4,
        route: "my_network",
      },
    ],
  },
];

export default desktopLinks;
