import React from "react";
import useLogin from "../Hooks/useLogin";
import { handleForgot } from "../Hooks/useLogin";
const LoginForm = () => {
  const { handleFormChange, handleSubmit, error, isLoading, disabled } =
    useLogin();

  return (
    <div className="animated fadeInUp col-10 col-xxl-7 d-flex flex-column gap-5 align-items-left">
      <div className="fs-3 text-black fw-bolder">Welcome to KOIN</div>
      <div>
        <form onSubmit={handleSubmit} onChange={handleFormChange}>
          <div className="mb-3">
            <label className="form-label fs-6">Email address</label>
            <input
              name="email"
              placeholder="Plase input the email..."
              type="text"
              className="form-control bg-body-secondary p-3 focus-ring border-0 min-width-200"
            />
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <label className="form-label fs-6">Password</label>
              <label
                role="button"
                onClick={handleForgot}
                className="form-label fs-6"
              >
                Forgot Password ?
              </label>
            </div>
            <input
              name="password"
              autoComplete="on"
              placeholder="Plase input the password..."
              type="password"
              className="form-control bg-body-secondary p-3 focus-ring border-0 min-width-200"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center error">
            {error}
          </div>
          <button
            disabled={!disabled || isLoading}
            type="submit"
            className="btn btn-primary border-0 fw-bolder fs-6 mt-2 px-4 py-3"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
