import React from "react";

const Notification = ({ name, action, receivedTimeAgo, avatar }) => {
  return (
    <li className="py-2 rounded mb-1 text-koin border-bottom border-koin1">
      <div role="button" className="d-flex">
        <img className="avatar rounded-circle" src={avatar} alt="" />
        <div className="flex-fill ms-2">
          <p className="d-flex justify-content-between mb-0">
            <span className="font-weight-bold">{name}</span>
            <small className="time-ago">{receivedTimeAgo}</small>
          </p>

          <span>{action}</span>
        </div>
      </div>
    </li>
  );
};

export default Notification;
