import React, { useEffect, useCallback, memo } from "react";
import { useUserProvider } from "../../Context/UserProvider";
import Login from "../../Component/Auth/Login/Login";
import FullScreenLoader from "../../Component/FullScreenLoader/FullScreenLoader";
import useLogin from "../../Component/Auth/Hooks/useLogin";
import jwtDecode from "jwt-decode";
import { useSignal, useSignalEffect } from "@preact/signals-react";

const AuthentificatedGuard = ({ children }) => {
  const { user, userLoading } = useUserProvider();
  const isAuthentificated = useSignal(!!user.value);
  const { refreshToken } = useLogin();

  const checkTokenExpiration = useCallback(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const currentTime = Math.floor(Date.now() / 1000);
      const decodedToken = jwtDecode(token);
      const { exp } = decodedToken;
      const tokenExpirationThreshold = 60 * 5;

      if (exp - currentTime < tokenExpirationThreshold) {
        refreshToken(token);
      }
    }
  }, [refreshToken]);

  useSignalEffect(() => {
    isAuthentificated.value = !!user.value;
  }, );

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 1000 * 60 * 5);

    return () => {
      clearInterval(interval);
    };
  }, [checkTokenExpiration]);

  const renderComponent = useCallback(() => {
    if (!isAuthentificated.value) {
      return userLoading.value ? <FullScreenLoader /> : <Login />;
    }
    return children;
  }, [children, isAuthentificated, userLoading]);

  return renderComponent();
};

export default memo(AuthentificatedGuard);
