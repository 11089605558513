// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  background-color: var(--background-color);
  order: 3;
  flex: 1 1;
}
.main .main-login {
  background-color: var(--primary-color);
}
.main .body {
  flex-flow: column;
  display: -ms-flexbox;
  flex-grow: 1;
  min-width: 0;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/Default/styles.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EAMA,QAAA;EAGA,SAAA;AAFJ;AANI;EACI,sCAAA;AAQR;AAAI;EAII,iBAAA;EAEA,oBAAA;EAGA,YAAA;EACA,YAAA;AAER","sourcesContent":[".main{\n    background-color: var(--background-color);\n    .main-login {\n        background-color:var(--primary-color);\n    }\n    -webkit-box-ordinal-group: 4;\n    -ms-flex-order: 3;\n    order: 3;\n    -webkit-box-flex: 1;\n    -ms-flex: 1;\n    flex: 1;\n    .body{\n        -webkit-box-orient: vertical;\n        -webkit-box-direction: normal;\n        -ms-flex-flow: column;\n        flex-flow: column;\n        display: -webkit-box;\n        display: -ms-flexbox;\n        -webkit-box-flex: 1;\n        -ms-flex-positive: 1;\n        flex-grow: 1;\n        min-width: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
