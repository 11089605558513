import React, { useMemo } from "react";
import SidebarExtenstion from "./SidebarExtenstion";
import SidebarIcon from "./SidebarIcon";
import desktopLinks from "../../Data/Menu/DesktopMenu";
import SwitchButton from "../../SwitchButton/SwitchButton";
import { useNavigate } from "react-router";
import "./styles.scss";
import {
  isSidebarToggled,
  handleSidebarToggle,
} from "../../../Signals/sidebar";
const Sidebar = () => {
  const navigate = useNavigate();

  const icons = useMemo(() => {
    return desktopLinks.map((element, index) => {
      return (
        <SidebarIcon
          key={index}
          index={index}
          children={element.children}
          image={element.image}
          title={element.title}
        />
      );
    });
  }, []);
  return (
    <div
      className={`sidebar position-relative ${
        !isSidebarToggled.value ? "shady" : ""
      }  d-flex`}
    >
      <div className="d-flex flex-column justify-content-between align-items-center">
        <div className="d-flex flex-column justify-content-between justify-content-center py-5 px-4">
          <div className="logo d-flex flex-column gap-3" role="button">
            <img
              onClick={() => navigate("/dashboard")}
              width={45}
              height={45}
              alt="Logo"
              className="test"
              src="https://d235a6fku87tir.cloudfront.net/images/KOIN_symbol_BLACK.png"
            />
            <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
              <ul className="nav flex-column" role="tablist">
                {icons}
              </ul>
            </div>
          </div>
        </div>

        <SwitchButton />
      </div>
      <button
        onClick={handleSidebarToggle}
        className={`fw-bold extend-sidebar-button text-white border-0 position-absolute ${
          isSidebarToggled.value ? "toggle-button" : "extend-button"
        } p-2`}
      >
        {isSidebarToggled.value ? "<" : ">"}
      </button>
      <SidebarExtenstion isSidebarToggled={isSidebarToggled.value} />
    </div>
  );
};

export default Sidebar;
