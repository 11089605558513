import React, { useMemo } from "react";

import mobileLinks from "../../Data/Menu/MobileMenu";
import MobileSidebarLink from "./MobileSidebarLink";

const MobileSidebarList = () => {
  const links = useMemo(() => {
    return mobileLinks.map((link, index) => {
      return (
        <MobileSidebarLink
          key={index}
          image={link.image}
          title={link.title}
          route={link.route}
        />
      );
    });
  }, []);
  return <div>{links}</div>;
};

export default MobileSidebarList;
