import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import useActivekey from "../../../Hooks/useActivekey";
import { isDarkTheme } from "../../../Signals/theme";

const SidebarExtensionLink = ({ title, action, image, route }) => {
  const { activeKey } = useActivekey(route);

  return (
    <Link to={route}>
      <div
        role="button"
        className={`d-flex gap-3 sidebar-link text-nowrap ${
          activeKey ? "active" : ""
        } p-2 rounded`}
      >
        <div className="d-flex align-items-center flex-row justify-content-center">
          <span className="rounded p-2">
            <img
              className={isDarkTheme.value ? "imageColors" : ""}
              width={20}
              height={20}
              alt=""
              src={image}
            />
          </span>
        </div>
        <div className="text-nowrap">
          <div className="link-action  fs-6 link-text">{title}</div>
          <div className="link-action fs-8 fw-medium">{action}</div>
        </div>
      </div>
    </Link>
  );
};

export default SidebarExtensionLink;
