import React from "react";
import "./styles.scss";
import {
  isSidebarToggled,
  handleSidebarToggle,
  handleSidebarExtensionIndexLink,
  sidebarExtensionIndexLinks,
} from "../../../Signals/sidebar";
const SidebarIcon = ({ title, index, image }) => {
  const handleIconClick = () => {
    if (!isSidebarToggled.value) {
      handleSidebarToggle();
    }
  };

  return (
    <li
      role="button"
      onClick={handleIconClick}
      className="nav-item mb-4 text-center"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title={title}
    >
      <span
        onClick={() => handleSidebarExtensionIndexLink(index)}
        className={`toggled-sidebar-icon ${
          index === sidebarExtensionIndexLinks.value ? "active" : ""
        } svg-icon svg-icon-xl p-2 rounded`}
      >
        <img alt="" src={image} />
      </span>
    </li>
  );
};

export default SidebarIcon;
