import React from "react";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import useMediaQuery from "../../../Hooks/useMediaQuery";
import { useUserProvider } from "../../../Context/UserProvider";
import LoginSideImage from "../LoginSideImage";

import { showForgotPasswordForm } from "../Hooks/useLogin";
const Login = () => {
  const isSmallDevice = useMediaQuery("max", "width", 1400);
  const { user } = useUserProvider();

  if (!!user.value) {
    return null;
  }
  return (
    <div
      className={`body d-flex  ${
        isSmallDevice.value ? "flex-column" : ""
      }  align-items-center align-items-stretch`}
    >
      <LoginSideImage />

      <div className="col-12 col-xxl-7 d-flex p-5 flex-column justify-content-center align-items-center">
        {showForgotPasswordForm.value ? <ForgotPasswordForm /> : <LoginForm />}
        <a
          href={`${process.env.REACT_APP_KOIN_LEGAL_BASE}/tos`}
          target="_blank"
          className="text-primary top-100 start-25 fw-bolder"
          rel="noreferrer"
        >
          Terms
        </a>
      </div>
    </div>
  );
};

export default Login;
