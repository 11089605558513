// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icons-list .icon {
    align-items: center;
    background: var(--card-color);
    border: 1px solid var(--border-color);
    padding: 20px;
    text-align: center;
}

.icons-list .icon i {
    font-size: 26px;
    width: 30px;
    display: inline-block;
}

.icons-list .icon .label {
    color: var(--color-400);
}

.icons-list .icon:hover {
    background-color: var(--primary-color);
}

.icons-list .icon:hover i,
.icons-list .icon:hover .label {
    color: #FFFFFF;
}

.icons-lists .icon { align-items: center; background: var(--card-color); border: 1px solid var(--border-color); padding: 10px 20px; display: flex;flex-direction: column;}
.icons-lists .icon i { font-size: 24px; margin-bottom: 10px;}
.icons-lists .icon:hover{background-color: var(--primary-color);}
.icons-lists .icon:hover i{ color: #FFFFFF;}`, "",{"version":3,"sources":["webpack://./src/assets/css/icon.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,qCAAqC;IACrC,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;;IAEI,cAAc;AAClB;;AAEA,qBAAqB,mBAAmB,EAAE,6BAA6B,EAAE,qCAAqC,EAAE,kBAAkB,EAAE,aAAa,CAAC,sBAAsB,CAAC;AACzK,uBAAuB,eAAe,EAAE,mBAAmB,CAAC;AAC5D,yBAAyB,sCAAsC,CAAC;AAChE,4BAA4B,cAAc,CAAC","sourcesContent":[".icons-list .icon {\n    align-items: center;\n    background: var(--card-color);\n    border: 1px solid var(--border-color);\n    padding: 20px;\n    text-align: center;\n}\n\n.icons-list .icon i {\n    font-size: 26px;\n    width: 30px;\n    display: inline-block;\n}\n\n.icons-list .icon .label {\n    color: var(--color-400);\n}\n\n.icons-list .icon:hover {\n    background-color: var(--primary-color);\n}\n\n.icons-list .icon:hover i,\n.icons-list .icon:hover .label {\n    color: #FFFFFF;\n}\n\n.icons-lists .icon { align-items: center; background: var(--card-color); border: 1px solid var(--border-color); padding: 10px 20px; display: flex;flex-direction: column;}\n.icons-lists .icon i { font-size: 24px; margin-bottom: 10px;}\n.icons-lists .icon:hover{background-color: var(--primary-color);}\n.icons-lists .icon:hover i{ color: #FFFFFF;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
