import React from "react";
import Spin from "../Spin/Spin";

const FullScreenLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Spin />
    </div>
  );
};

export default FullScreenLoader;
