import React from "react";
import "./styles.scss";
const Spin = () => {
  return (
    <div className="lds-ring position-relative">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spin;
