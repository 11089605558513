import { useMemo } from "react";
import { useLocation } from "react-router";
import icons from "../Component/Data/Menu/MobileMenu";
import { effect, useSignal } from "@preact/signals-react";

const useGetPageName = () => {
  const location = useLocation();
  const currentSegments = useMemo(
    () => location.pathname.split("/").filter(Boolean),
    [location.pathname]
  );

  const currentKey = currentSegments.slice(1).join("/");

  const pageName = useSignal("");

  const routeToTitleMap = icons.reduce((result, item) => {
    result[item.route] = item.pageTitle;
    return result;
  }, {});

  effect(() => {
    if (currentKey === "/dashboard") {
      pageName.value = "Dashboard";
      return;
    }
    pageName.value = routeToTitleMap[currentKey];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return pageName;
};

export default useGetPageName;
