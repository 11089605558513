import React from "react";
import { Dropdown } from "react-bootstrap";
import Notification from "./Notification";
import { useSignal } from "@preact/signals-react";
import P1 from "../../assets/images/xs/avatar1.svg";
import "./styles.scss";

const Notifications = () => {
  const isOpen = useSignal(false);

  const handleClose = () => {
    isOpen.value = false;
  };

  const handleToggle = () => {
    isOpen.value = !isOpen.value;
  };

  return (
    <Dropdown
      className="dropdown notifications mx-2 mx-lg-3"
      show={isOpen.value}
      onToggle={handleToggle}
    >
      <Dropdown.Toggle
        as="s"
        className="nav-link dropdown-toggle pulse"
        role="button"
        data-bs-toggle="dropdown"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="25px"
          height="25px"
          viewBox="0 0 38 38"
        >
          <path
            style={{ fill: "var(--text-color)" }}
            className="st0"
            d="M33.02,32c-0.598-0.363-1.081-3.663-1.4-5.847c-0.851-5.899-2.199-15.254-9.101-17.604  C23.433,7.643,24,6.386,24,5c0-2.757-2.243-5-5-5s-5,2.243-5,5c0,1.386,0.567,2.643,1.482,3.549  c-6.902,2.35-8.25,11.705-9.101,17.604C6.209,27.324,5.991,28.813,5.733,30h2.042c0.192-0.961,0.376-2.127,0.586-3.562  C9.36,19.501,10.73,10,19,10c8.27,0,9.64,9.501,10.641,16.442c0.386,2.636,0.682,4.394,1.108,5.558H2v2h12.101  c0.464,2.279,2.485,4,4.899,4c2.415,0,4.435-1.721,4.899-4H36v-2H33.02z M19,8c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3  S20.654,8,19,8z M19,36c-1.304,0-2.416-0.836-2.829-2h5.658C21.416,35.164,20.304,36,19,36z"
          ></path>
        </svg>
        <span className="pulse-ring"></span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu rounded-pill border-0 dropdown-animation dropdown-menu-md-end p-0 m-0">
        <div className="border-0 w380">
          <div className="notification-card-header shadow border-0 bg-primary p-3">
            <h5 className="mb-0 text-koin font-weight-light d-flex justify-content-between">
              <span>Notifications</span>
              <i
                role="button"
                className="fa fa-close"
                onClick={handleClose}
              ></i>
            </h5>
          </div>
          <div className="shadow tab-content bg-koin card-body">
            <div className="tab-pane bg-koin fade show active">
              <ul className="list-unstyled  notifications-list list mb-0 p-2">
                <Notification
                  name="Melanie Oliver"
                  receivedTimeAgo="1HR"
                  action="User Document Verified"
                  avatar={P1}
                />
              </ul>
            </div>
          </div>
          <div className="notifications-card-footer shadow text-koin bg-koin2 text-center border-top-0 bg-primary text-white py-3">
            View all notifications
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Notifications;
