import { toast } from "react-toastify";
import koinHeaders from "../Helper/koinHeaders";
import { toastMessage } from "../Helper/toastMessage";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const fetchUserData = async (token) => {
  if (!token) {
    return null;
  }

  try {
    const response = await fetch(`${apiUrl}/users/me`, {
      headers: koinHeaders(true),
    });

    const json = await response.json();

    if (json.error) {
      return null;
    } else {
      return json;
    }
  } catch (error) {
    console.error(error);
    toast.error(toastMessage.internal);
  }
};

export const updateUserProfile = async (userData, userId) => {
  try {
    const response = await fetch(`${apiUrl}/users/${userId}`, {
      method: "PUT",
      headers: koinHeaders(true),
      body: JSON.stringify(userData),
    });

    const json = await response.json();

    if (json.error) {
      return null;
    } else {
      toast.success("Updated successfully");
      return json;
    }
  } catch (error) {
    console.error(error);
    toast.error(toastMessage.internal);
  }
};

export const changePassword = async (data) => {
  try {
    const response = await fetch(`${apiUrl}/auth/change-password`, {
      method: "POST",
      headers: koinHeaders(true),
      body: JSON.stringify({
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      }),
    });
    return response.json();
  } catch (error) {
    toast.error(toastMessage.internal);
    console.log(error);
  }
};
