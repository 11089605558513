const koinHeaders = (requireAuth) => {
  return {
    accept: "application/json",
    Authorization: requireAuth
      ? `Bearer ${sessionStorage.getItem("token")}`
      : undefined,
    "Content-Type": "application/json",
  };
};

export default koinHeaders;
