import { lazy, Suspense, useEffect } from "react";
import { useLocation, useRoutes } from "react-router";
import FullScreenLoader from "../Component/FullScreenLoader/FullScreenLoader";
import DefaultLayout from "../Layouts/Default/DefaultLayout";
import AuthentificatedGuard from "../Guards/Authentificated/AuthentificatedGuard";
import GuestGuard from "../Guards/GurestGuart/GuestGuard";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const Page = {
  Project: {
    Dashboard: {
      Dashboard: Loadable(lazy(() => import("../Screen/Dashboard/Dashboard"))),
      MarketingSettings: Loadable(
        lazy(() => import("../Screen/MarketingSettings/MarketingSettings"))
      ),
      PersonalInfo: Loadable(
        lazy(() => import("../Screen/PersonalInfo/PersonalInfo"))
      ),
      Security: Loadable(lazy(() => import("../Screen/Security/Security"))),
      MyInvites: Loadable(lazy(() => import("../Screen/MyInvites/MyInvites"))),
      InviteFriends: Loadable(
        lazy(() => import("../Screen/InviteFriends/InviteFriends"))
      ),
      MyBonuses: Loadable(lazy(() => import("../Screen/MyBonuses/MyBonuses"))),
      MyRewards: Loadable(lazy(() => import("../Screen/MyRewards/MyRewards"))),
      MyRewardsChart: Loadable(
        lazy(() => import("../Screen/MyRewardsChart/MyRewardsChart"))
      ),
      MyNetwork: Loadable(lazy(() => import("../Screen/MyNetwork/MyNetwork"))),
    },
  },
  Login: Loadable(lazy(() => import("../Component/Auth/Login/Login"))),
  ResetPassword: Loadable(
    lazy(() => import("../Component/Auth/ResetPassword/ResetPassword"))
  ),
  SendAgain: Loadable(
    lazy(() => import("../Component/Auth/SendAgain/SendAgain"))
  ),
  Error404: Loadable(lazy(() => import("../Component/Auth/ErrorPage"))),
  Home: Loadable(lazy(() => import("../Screen/Home/Home"))),
};
//
const routes = [
  {
    path: "/dashboard",
    element: (
      <AuthentificatedGuard>
        <DefaultLayout />
      </AuthentificatedGuard>
    ),
    children: [
      {
        index: true,
        element: <Page.Project.Dashboard.Dashboard />,
      },
      {
        index: true,
        path: "uprofile/edit",
        element: <Page.Project.Dashboard.PersonalInfo />,
      },
      {
        index: true,
        path: "marketing_settings",
        element: <Page.Project.Dashboard.MarketingSettings />,
      },
      {
        index: true,
        path: "upass/edit",
        element: <Page.Project.Dashboard.Security />,
      },
      {
        index: true,
        path: "my_invites",
        element: <Page.Project.Dashboard.MyInvites />,
      },
      {
        index: true,
        path: "invite",
        element: <Page.Project.Dashboard.InviteFriends />,
      },
      {
        index: true,
        path: "my_bonuses",
        element: <Page.Project.Dashboard.MyBonuses />,
      },
      {
        index: true,
        path: "my_rewards",
        element: <Page.Project.Dashboard.MyRewards />,
      },
      {
        index: true,
        path: "my_rewards_chart",
        element: <Page.Project.Dashboard.MyRewardsChart />,
      },
      {
        index: true,
        path: "my_network",
        element: <Page.Project.Dashboard.MyNetwork />,
      },
    ],
  },
  {
    path: "/auth/login",
    element: (
      <GuestGuard>
        <Page.Login />
      </GuestGuard>
    ),
  },
  {
    path: "/auth/reset_password/myToken/:token",
    element: (
      <GuestGuard>
        <Page.ResetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "/auth/send_again",
    element: (
      <GuestGuard>
        <Page.SendAgain />
      </GuestGuard>
    ),
  },
  {
    path: "",
    element: <Page.Home />,
  },

  {
    path: "*",
    element: <Page.Error404 />,
  },
];

const Router = () => {
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);
  return useRoutes(routes);
};

export default Router;
