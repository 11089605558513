import React from "react";
import useActivekey from "../../../Hooks/useActivekey";
import { useUserProvider } from "../../../Context/UserProvider";
import { isDarkTheme } from "../../../Signals/theme";
const SidebarLogoutLink = ({ image, title, action, route }) => {
  const { logOut } = useUserProvider();
  const { activeKey } = useActivekey(route);

  return (
    <div
      onClick={logOut}
      role="button"
      className={`d-flex gap-3  sidebar-link ${
        activeKey ? "active" : ""
      } p-2 rounded`}
    >
      <div className="d-flex align-items-center justify-content-center">
        <span className="rounded p-2">
          <img
            className={isDarkTheme.value ? "imageColors" : ""}
            width={20}
            height={20}
            alt=""
            src={image}
          />
        </span>
      </div>
      <div className="text-nowrap">
        <div className="link-action fs-6 link-text">{title}</div>
        <div className="link-action fs-8 fw-medium">{action}</div>
      </div>
    </div>
  );
};

export default SidebarLogoutLink;
