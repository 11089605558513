import React, { useEffect } from "react";
const FrameBusterWrapper = ({ children }) => {
  useEffect(() => {
    const isInIframe = window !== window.top;

    if (isInIframe) {
      window.top.location.href = window.location.href;
    }
  }, []);

  return <div>{children}</div>;
};

export default FrameBusterWrapper;
