import React from "react";
import { isDarkTheme } from "../../Signals/theme";
import MobileSidebar from "../../Component/App/MobileSidebar/MobileSidebar";
import Sidebar from "../../Component/App/Sidebar/Sidebar";
import Terms from "../../Component/Terms/Terms";
import Header from "../../Component/Header/Header";
import useMediaQuery from "../../Hooks/useMediaQuery";
import { useUserProvider } from "../../Context/UserProvider";
import { Outlet } from "react-router";
import "./styles.scss";
import { isMobileSidebarToggled } from "../../Signals/mobileSidebar";
import FullScreenLoader from "../../Component/FullScreenLoader/FullScreenLoader";
const DefaultLayout = ({ children }) => {
  const isSmallDevice = useMediaQuery("max", "width", 1280);

  const { userLoading } = useUserProvider();
  return (
    <div className={isDarkTheme.value ? "theme-dark-koin" : "theme-light-koin"}>
      <div className="d-flex">
        {isSmallDevice.value ? <MobileSidebar /> : <Sidebar />}

        <div className="main vh-100 overflow-auto d-flex flex-column justify-content-between px-lg-4 px-md-4">
          <div
            className={
              isSmallDevice.value && isMobileSidebarToggled.value ? "blur" : ""
            }
          >
            <Header />
            <div className="body d-flex justify-content-between py-3">
              {userLoading.value ? (
                <FullScreenLoader />
              ) : (
                children || <Outlet />
              )}
            </div>
          </div>
          <div
            className={
              isSmallDevice.value && isMobileSidebarToggled.value ? "blur" : ""
            }
          >
            <Terms />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
