import Gift from "../../../assets/svg/Gift.svg";
import Git4 from "../../../assets/svg/Git4.svg";
import IncomingBox from "../../../assets/svg/Incoming-box.svg";
import Settings from "../../../assets/svg/Settings-2.svg";
import Lock from "../../../assets/svg/Lock.svg";
import Visible from "../../../assets/svg/Visible.svg";
import OutGoingEmail from "../../../assets/svg/Outgoing-mail.svg";
import Wallet3 from "../../../assets/svg/Wallet3.svg";
import Layers from "../../../assets/svg/Layers.svg";
const mobileLinks = [
  {
    title: "Dashboard",
    pageTitle: "Dashboard",
    action: "Click to Edit",
    image: Layers,
    route: "",
  },
  {
    title: "Marketing Settings",
    pageTitle: "Marketing Settings",
    action: "Click to Edit",
    image: IncomingBox,
    route: "marketing_settings",
  },
  {
    title: "Personal Info",
    pageTitle: "Personal Info",
    action: "Click to Edit",
    image: Settings,
    route: "uprofile/edit",
  },
  {
    title: "Security",
    pageTitle: "Security",
    action: "Set Password",
    image: Lock,
    route: "upass/edit",
  },
  {
    title: "My Invites",
    pageTitle: "Invites",
    action: "View",
    image: Visible,
    route: "my_invites",
  },
  {
    title: "Invite Friends",
    pageTitle: "Invite Friends",
    action: "Send an Invitation",
    image: OutGoingEmail,
    route: "invite",
  },
  {
    title: "My Bonuses",
    pageTitle: "Bonuses",
    action: "Click to View",
    image: Gift,
    route: "my_bonuses",
  },
  {
    title: "My Rewards",
    pageTitle: "Rewards",
    action: "Click to View",
    image: Wallet3,
    route: "my_rewards",
  },
  {
    title: "My Rewards Chart",
    pageTitle: "Rewards Chart",
    action: "Click to View",
    image: Wallet3,
    route: "my_rewards_chart",
  },
  {
    title: "My Network",
    pageTitle: "Network",
    action: "Click to View",
    image: Git4,
    route: "my_network",
  },
];

export default mobileLinks;
