// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-sidebar {
  background-color: var(--mobile-sidebar-color);
  width: 0;
  transition: width 0.2s;
  right: 0;
}
.mobile-sidebar.active {
  width: 325px;
}
.mobile-sidebar .mobile-menu {
  background-color: var(--mobile-menu-color);
}
.mobile-sidebar .mobile-sidebar-link:hover {
  background-color: var(--mobile-sidebar-link-hover-color);
}
.mobile-sidebar .mobile-sidebar-link.active {
  background-color: var(--mobile-sidebar-link-hover-color);
}
.mobile-sidebar .social-media i {
  font-size: 18px;
  color: #ffffff;
  background-color: var(--twiter-background-color);
}
.mobile-sidebar .social-media a {
  color: white;
}
.mobile-sidebar .sidebar-close-button {
  background-color: var(--mobile-sidebar-color);
}

.double-theme-switch-button {
  background-color: var(--bg-switch-theme-button);
}
.double-theme-switch-button button {
  background-color: var(--bg-switch-theme-button);
}
.double-theme-switch-button button:disabled {
  background-color: var(--koin-btn-barkground-color);
}`, "",{"version":3,"sources":["webpack://./src/Component/App/MobileSidebar/styles.scss"],"names":[],"mappings":"AAAA;EACI,6CAAA;EACA,QAAA;EACA,sBAAA;EACA,QAAA;AACJ;AAAI;EACI,YAAA;AAER;AAAI;EACI,0CAAA;AAER;AACQ;EACI,wDAAA;AACZ;AACQ;EACI,wDAAA;AACZ;AAKQ;EAEI,eAAA;EACA,cAAA;EACA,gDAAA;AAJZ;AAOQ;EACI,YAAA;AALZ;AAUI;EACI,6CAAA;AARR;;AAWA;EACI,+CAAA;AARJ;AASI;EACI,+CAAA;AAPR;AAQQ;EACI,kDAAA;AANZ","sourcesContent":[".mobile-sidebar {\n    background-color: var(--mobile-sidebar-color);\n    width: 0;\n    transition: width 0.2s;\n    right: 0;\n    &.active {\n        width: 325px;\n    }\n    .mobile-menu {\n        background-color: var(--mobile-menu-color);\n    }\n    .mobile-sidebar-link {\n        &:hover {\n            background-color: var(--mobile-sidebar-link-hover-color);\n        }\n        &.active {\n            background-color: var(--mobile-sidebar-link-hover-color);\n        }\n    }\n\n\n    .social-media {\n        i {\n   \n            font-size: 18px;\n            color: #ffffff;\n            background-color: var(--twiter-background-color);\n            \n        }\n        a {\n            color: white;\n        }\n     \n    }\n\n    .sidebar-close-button{\n        background-color: var(--mobile-sidebar-color);\n    }\n}\n.double-theme-switch-button{\n    background-color:var(--bg-switch-theme-button);\n    button {\n        background-color: var(--bg-switch-theme-button);\n        &:disabled {\n            background-color: var(--koin-btn-barkground-color);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
