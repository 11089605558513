import React, { memo } from "react";
import { useUserProvider } from "../../Context/UserProvider";
import { Navigate } from "react-router";
import FullScreenLoader from "../../Component/FullScreenLoader/FullScreenLoader";
import { useSignal, useSignalEffect } from "@preact/signals-react";
const GuestGuard = ({ children }) => {
  const { user, userLoading } = useUserProvider();
  const isAuthentificated = useSignal(!!user.value);

  useSignalEffect(() => {
    isAuthentificated.value = !!user.value;
  });

  return userLoading.value ? (
    <FullScreenLoader />
  ) : isAuthentificated.value ? (
    <Navigate to="/dashboard" />
  ) : (
    children
  );
};

export default memo(GuestGuard);
