// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetPasswordError {
  color: #8d57fb;
  border: 1px solid #8d57fb;
}

.flexer {
  flex: 1 1 !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/Auth/styles.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,yBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ","sourcesContent":[".resetPasswordError{\n    color:#8d57fb;\n    border: 1px solid #8d57fb;\n}\n\n.flexer {\n    flex:1 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
