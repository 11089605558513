// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-switch {
  background-color: var(--sidebar-hover-icon-color);
}
.button-switch button {
  background-color: var(--sidebar-color);
}`, "",{"version":3,"sources":["webpack://./src/Component/SwitchButton/styles.scss"],"names":[],"mappings":"AAAA;EACI,iDAAA;AACJ;AAAI;EACI,sCAAA;AAER","sourcesContent":[".button-switch {\n    background-color: var(--sidebar-hover-icon-color) ;\n    button {\n        background-color: var(--sidebar-color);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
