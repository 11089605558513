import { useLocation, useNavigate } from "react-router";
import { login } from "../../../Services/authService";
import { signal, useSignal } from "@preact/signals-react";
export const showForgotPasswordForm = signal(false);
export const handleForgot = () => {
  showForgotPasswordForm.value = !showForgotPasswordForm.value;
};
const useLogin = () => {
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const form = useSignal({
    email: "",
    password: "",
  });
  const isLoading = useSignal(false);
  const error = useSignal("");

  const navigate = useNavigate();
  const disabled =
    form.value.email !== "" &&
    form.value.password !== "" &&
    form.value.email.trim() &&
    form.value.password.trim();
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    form.value = { ...form.value, [name]: value };

    if (error) {
      error.value = "";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    isLoading.value = true;
    try {
      const loginData = {
        identifier: form.value.email,
        password: form.value.password,
      };

      const responseData = await login(loginData);
      if (responseData.error) {
        error.value = responseData.error.message;
      } else {
        sessionStorage.setItem("token", responseData.jwt);
        if (location.pathname !== "/auth/login") {
          navigate(location.pathname);
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      isLoading.value = false;
    }
  };

  const refreshToken = async (token) => {
    try {
      const response = await fetch(`${apiUrl}/auth/refreshToken`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      });
      const json = await response.json();
      sessionStorage.setItem("token", json.jwt);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    form,
    handleFormChange,
    handleSubmit,
    error,
    refreshToken,
    isLoading,
    disabled,
    showForgotPasswordForm,
    handleForgot,
  };
};

export default useLogin;
