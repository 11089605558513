import React from "react";
import MobileSidebarList from "./MobileSidebarList";
import { useUserProvider } from "../../../Context/UserProvider";
import SwitchButton from "../../SwitchButton/SwitchButton";
import XTwitter from "../../../assets/svg/XTwitter.svg";
import "./styles.scss";
import {
  handleIsMobileSidebarToggled,
  isMobileSidebarToggled,
} from "../../../Signals/mobileSidebar";
const MobileSidebar = () => {
  const { logOut } = useUserProvider();
  const landingUrl = process.env.REACT_APP_KOIN_LANDING_URL;
  return (
    <div
      className={`mobile-sidebar vh-100 z-1 overflow-auto position-absolute d-flex flex-column ${
        isMobileSidebarToggled.value ? "active " : ""
      }`}
    >
      <div className="px-4 py-5">
        <div className="text-end">
          <button
            className="fs-4 border-0 sidebar-close-button"
            onClick={handleIsMobileSidebarToggled}
          >
            <i role="button" className="fa fa-close text-white mb-3"></i>
          </button>
        </div>
        <div className="mobile-menu overflow-hidden rounded ">
          <MobileSidebarList />
        </div>
        <button
          onClick={logOut}
          className="mt-4 w-100 mobile-sidebar-button btn-koin2 fw-bold p-2 bg-secoundary border-0 rounded"
        >
          Logout
        </button>
        <SwitchButton />
        <div className="d-flex justify-content-center mt-5">
          <div className="social-media d-flex align-items-center gap-3">
            <span>
              <a
                target="_blank"
                href={process.env.REACT_APP_KOIN_TWITTER_LANDING_URL}
                rel="noreferrer"
              >
                <img
                  alt="X-Twitter"
                  src={XTwitter}
                  width={25}
                  height={25}
                ></img>
              </a>
            </span>
            <a href={`${landingUrl}/tos`}>Terms</a>
            <a href={`${landingUrl}/privacystatement`}>Privacy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;
