import React, { useCallback } from "react";
import useForgotPassword from "../Hooks/useForgotPassword";

import { handleForgot } from "../Hooks/useLogin";
const ForgotPasswordForm = () => {
  const { handleEmailChange, handleSubmit, disabled } =
    useForgotPassword("forgot_password");

  const submit = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <div className="animated fadeInUp col-10 col-xxl-7 d-flex flex-column gap-5 align-items-left">
      <i className="fade-in-up"></i>
      <div className="d-flex flex-column gap-2">
        <div className="fs-3 text-black fw-bolder">Forgotten Password ?</div>

        <div className="fs-5 .text-body-secondary fw-bolder">
          Enter your email to reset your password
        </div>
      </div>
      <form onSubmit={submit}>
        <div className="mb-3">
          <input
            onChange={handleEmailChange}
            autoComplete="on"
            placeholder="Email"
            type="email"
            className="form-control bg-body-secondary p-3 focus-ring border-0 min-width-200"
          />
        </div>
        <div className="d-flex flex-row gap-3">
          <button
            disabled={disabled}
            type="submit"
            className="btn btn-primary border-0 fw-bolder fs-6 mt-2 px-4 py-3"
          >
            Sign In
          </button>
          <button
            className="btn btn-light-primary  border-0 fw-bolder fs-6 mt-2 px-4 py-3"
            onClick={handleForgot}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
