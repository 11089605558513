import React, { useMemo } from "react";
import SidebarExtensionLink from "./SidebarExtensionLink";
import desktopLink from "../../Data/Menu/DesktopMenu";
import SidebarLogoutLink from "./SidebarLogoutLink";
import { sidebarExtensionIndexLinks } from "../../../Signals/sidebar";
const SidebarExtenstion = ({ isSidebarToggled }) => {
  const list = desktopLink[sidebarExtensionIndexLinks].children;
  const title = desktopLink[sidebarExtensionIndexLinks].name;
  const links = useMemo(() => {
    return (
      list &&
      list.map((element, index) => {
        if (element.title !== "Logout") {
          return (
            <SidebarExtensionLink
              key={index}
              title={element.title}
              action={element.action}
              image={element.image}
              route={element.route}
            />
          );
        } else {
          return (
            <SidebarLogoutLink
              key={index}
              title={element.title}
              action={element.action}
              image={element.image}
              route={element.route}
            />
          );
        }
      })
    );
  }, [list]);

  return (
    <div
      className={`sidebar-extension overflow-hidden bg-white  ${
        isSidebarToggled ? "active" : ""
      }`}
    >
      <div
        key={title}
        className="px-4 py-5 text-nowrap fade-in-element d-flex flex-column gap-4"
      >
        <div className="fw-bold fs-5">{title}</div>
        <div className="d-flex flex-column gap-2">{links}</div>
      </div>
    </div>
  );
};

export default SidebarExtenstion;
