import React from "react";
import Accomplishments from "../../assets/svg/Accomplishments.svg";
import "./styles.scss";
const LoginSideImage = () => {
  return (
    <div className="col-12 flexer min-vh-100 col-xxl-5 bg-koin3 d-flex p-5 flex-column justify-content-center align-items-center gap-5">
      <div>
        <img
          width="75px"
          height="75px"
          alt="Logo"
          src="https://d235a6fku87tir.cloudfront.net/images/symbol_green.png"
        />
      </div>
      <div className="h-100 d-flex align-items-center justify-content-center flex-column gap-5">
        <div className="fs-4  text-white fw-bolder text-center">
          Discover KOIN's Amazing Features & Possibilities
        </div>
        <img
          width={350}
          height={350}
          src={Accomplishments}
          alt="Imagine"
          className="img-fluid"
        />
      </div>
    </div>
  );
};

export default LoginSideImage;
