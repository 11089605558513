import React from "react";
import { BrowserRouter } from "react-router-dom";

import UserProvider from "./Context/UserProvider";
import Router from "./Pages/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Router />
        <ToastContainer />
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
