import { forgotPassword } from "../../../Services/authService";
import { useSignal } from "@preact/signals-react";
import { toast } from "react-toastify";
import { handleForgot } from "./useLogin";
const useForgotPassword = (fetchCase) => {
  const email = useSignal("");
  const isSuccessfully = useSignal(false);
  const loading = useSignal(false);

  const handleEmailChange = (event) => {
    email.value = event.target.value;
  };

  const handleSubmit = async () => {
    try {
      loading.value = true;
      const response = await forgotPassword(email);
      if (response.ok) {
        toast.success("Email sent successfully");
        isSuccessfully.value = true;
        if (fetchCase === "forgot_password") {
          handleForgot();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      loading.value = false;
    }
  };

  const disabled = loading.value || email.value === "";

  return { handleEmailChange, handleSubmit, disabled, isSuccessfully };
};

export default useForgotPassword;
