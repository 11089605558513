import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import useActivekey from "../../../Hooks/useActivekey";
import { handleIsMobileSidebarToggled } from "../../../Signals/mobileSidebar";
const MobileSidebarLink = ({ image, title, route }) => {
  const { activeKey } = useActivekey(route);
  return (
    <Link to={route}>
      <div
        onClick={handleIsMobileSidebarToggled}
        className={`px-2 py-3 d-flex fs-6 flex-row rounded mobile-sidebar-link text-nowrap text-white ${
          activeKey ? "active" : ""
        }`}
      >
        <div className="d-flex gap-3">
          <img
            className={"imageColors"}
            width={25}
            height={25}
            alt=""
            src={image}
          />
          {title}
        </div>
      </div>
    </Link>
  );
};

export default MobileSidebarLink;
