import { useSignal, useSignalEffect } from "@preact/signals-react";
const useMediaQuery = (type, dimension, value) => {
  const matches = useSignal(false);

  useSignalEffect(() => {
    const mediaQuery = window.matchMedia(`(${type}-${dimension}: ${value}px)`);
    const updateMatches = () => {
      matches.value = mediaQuery.matches;
    };
    updateMatches();
    const listener = () => {
      updateMatches();
    };
    mediaQuery.addEventListener("change", listener);
    return () => {
      mediaQuery.removeEventListener("change", listener);
    };
  });

  return matches;
};

export default useMediaQuery;
